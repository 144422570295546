<div class="app-historic-chats sl__simpleLayout">

  <header>
    <div class="sl__simpleLayout__header__content">
      <h1>{{ 'chat-ia.view.historic-chats.title' | translate }}</h1>
      <span>{{ 'chat-ia.view.historic-chats.subtitle' | translate }}</span>
    </div>

    <!--  Header Botões  -->
    <div class="sl__simpleLayout__header__nav">
      <app-button-icon-primary [id]="'btn-icon'" customClassBtn="padding: 0 16px;"
        [text]="'chat-ia.view.historic-chats.btn-new-chat' | translate " altText="" (click)="openNewChatAi()">
      </app-button-icon-primary>
    </div>
  </header>


  <div class="outer__wrapper">
    <!-- BODY -->
    <div nz-row>
      <div nz-col [nzSpan]="24">
        <div class="round-border">

          <div class="container__table">
            <nz-spin [nzSpinning]="isLoading" id="spinner">
              <app-generic-table
                [dataConfiguration]="configurationTable"
                [dataHeaders]="dataHeaderTable"
                [isLoading]="isLoading"
                [dataList]="dataListTable ? dataListTable : []"
                [editableConfiguration]="editableConfiguration"
                (changeValue)="onDataValueChanged($event)"
                (changeValueCol)="onChangeValueCol($event)"
                (changeHoverValue)="onChangeHoverValue($event)"
                (changeEditRow)="onChangeEditRow($event)">
              </app-generic-table>
            </nz-spin>
          </div>

        </div>
      </div>

    </div>
  </div>

</div>

<ng-template #templateChatIcon>
  <div class="d-flex__inline container__icons">
    <div class="container__img" (click)="onSendEmail()">
      <img 
        *ngIf="!showEditIcon && isSendEmail"
        class="icon__sendEmail_active"
        src="assets/icons/arrow_green-icon24.svg" 
        nz-tooltip="Esta conversas tem mais de 60 dias e não está mais disponível. Você pode enviar para seu e-mail e visualizá-la.">
      
        <img 
        *ngIf="!showEditIcon && !isSendEmail"
        class="icon__sendEmail_disable"
        src="assets/icons/arrow_grey-icon24.svg" 
        nz-tooltip="A conversa está sendo enviada para seu email.">
    </div>

    <div class="container__img" (click)="onEditRow()">
      <img src="assets/icons/edit_green-icon24.svg" *ngIf="showEditIcon">
    </div>
  </div>
</ng-template>


<!-- Chat -->
<ng-template #templateChatFilter>
  <div class="d-flex__inline">
    <app-generic-modal-search
      [cleanValues]="cleanValuesFilter"
      (changeSearchValue)="filterByTitle($event)"
      (changeCleanValue)="onCleanFilter($event)">
    </app-generic-modal-search>
  </div>
</ng-template>

<!-- Documents -->
<ng-template #templateDocumentFilter>
  <div class="d-flex__inline">
    <app-generic-modal-search
      [cleanValues]="cleanValuesFilter"
      (changeSearchValue)="filterByDocuments($event)"
      (changeCleanValue)="onCleanFilter($event)">
    </app-generic-modal-search>
  </div>
</ng-template>

<!-- Date -->
<ng-template #templateDateFilter>
  <app-modal-prediction-conclusion
    [cleanValues]="cleanValuesFilter"
    (changeApplyFilter)="filterByDate($event)"
    (changeCleanValues)="onCleanFilter($event)">
  </app-modal-prediction-conclusion>
</ng-template>

