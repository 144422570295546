import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {DisciplineListDto} from "../dto/discipline-list.dto";
import { HttpClient } from "@angular/common/http";
import {ALL_DISCIPLINES_API, DISCIPLINES_API, DISCIPLINES_TO_SCOPE_CHANGE_API} from "../../modules/projects/constants/apiEndpointsConstants";

@Injectable({
  providedIn: 'root'
})
export class DisciplinesService {

  constructor(private readonly http: HttpClient) { }

  getDisciplines(): Observable<DisciplineListDto> {
    return this.http.get<DisciplineListDto>(`${DISCIPLINES_API}`)
  }

  getDisciplinesToScopeChange(): Observable<DisciplineListDto> {
    return this.http.get<DisciplineListDto>(`${DISCIPLINES_TO_SCOPE_CHANGE_API}`)
  }

  getAllDisciplines(): Observable<DisciplineListDto> {
    return this.http.get<DisciplineListDto>(`${ALL_DISCIPLINES_API}`)
  }

  
}
