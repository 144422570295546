import { Component } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { UserProfileComponent } from 'src/app/modules/user-profile/user-profile.component';
import { UtilService } from 'src/app/shared/util.service';

@Component({
  selector: 'app-topbar-consultancy',
  templateUrl: './topbar-consultancy.component.html',
  styleUrls: ['./topbar-consultancy.component.scss']
})
export class TopbarConsultancyComponent {

  private flagUserProfile = false;
  private drawerRef;

  public notificationActiveFlag = false;
  nonProd: boolean = false;

  isViewConfigurationUser: boolean = false;
  configurationActive: boolean = true;


  constructor(
    private drawerService: NzDrawerService,
    private readonly util: UtilService,
  ){}

  userProfile() {
    if (!this.flagUserProfile) {
      const bodyStyle = {
        padding: 20,
      };
      this.drawerRef = this.drawerService.create<
        UserProfileComponent,
        { value: string },
        string
      >({
        nzContent: UserProfileComponent,
        nzOffsetX: 0,
        nzBodyStyle: bodyStyle,
        nzPlacement: 'right',
        nzTitle: 'Perfil',
        nzWidth: 300,
      });

      this.drawerRef.afterOpen.subscribe(() => {
        this.flagUserProfile = true;
      });

      this.drawerRef.afterClose.subscribe((data) => {
        this.flagUserProfile = false;
      });
    } else {
      this.drawerRef.close();
      this.drawerRef = null;
    }
  }
}
