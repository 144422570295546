import { Router } from '@angular/router';
import {Component, EventEmitter, OnInit, Input, Output, ViewContainerRef} from '@angular/core';

import { UserService } from 'src/app/shared/services/user.service';
import {
  SuspensionCancellationStatusMeService
} from "../../../../scope-change/service/suspension-cancellation-status-me/suspension-cancellation-status-me.service";
import {MessageService} from "../../../../../shared/common/services/message.service";
import {NzModalService} from "ng-zorro-antd/modal";
import {
  ConfirmationModalComponent
} from "../../../../../shared/components/confirmation-modal/confirmation-modal.component";
import { UtilService } from 'src/app/shared/util.service';
import { buildRoute, RoutesEnum } from 'src/app/shared/enum/routes.enum';

@Component({
  selector: 'app-request-analysis-status-me',
  templateUrl: './request-analysis-status-me.component.html',
  styleUrls: ['./request-analysis-status-me.component.scss'],
})
export class RequestingAnalysisStatusComponent implements OnInit {
  @Input() status: string;
  @Input() prazo: Date;
  @Input() confirmationSuspensionId!: number;
  @Input() suspensionStatus: any;
  @Input() projectId!: number;
  @Input() isSuspension!: boolean;
  @Input() confirmMessage?: string;
  @Input() cancelMessage?: string;
  @Input() confirmationUserName?: string;
  @Input() finalComments?: string;
  @Input() confirmationDate?: string;
  @Input() confirmationSendDate?: string = '';
  @Input() deadline?: string;


  @Output() messageConfirm = new EventEmitter<void>();
  @Output() cancelConfirm = new EventEmitter<void>();

  isUserDemandant: boolean;
  isUserEspecialist: boolean;
  isUserGestor: boolean;
  isUserLicensing: boolean;
  textButtonsConfirm: string;
  textButtonsCancel: string;
  suspensionResponse: boolean = false;
  suspensionView: boolean = true;
  badgeColor: string = '';
  badgeIcon: string = '';
  deadLineColor: string = '';
  deadLineDate: Date = new Date();

  constructor(
    private readonly _userService: UserService,
    private _router: Router,
    private readonly message: MessageService,
    private viewContainerRef: ViewContainerRef,
    private readonly modalService: NzModalService,
    private utilService: UtilService,
    private readonly suspensionCancellationService: SuspensionCancellationStatusMeService
  ) {}

  ngOnInit() {
    this.isUserDemandant = this._userService.isRoleLoggedUser('ROLE_DEMANDANTE');
    this.isUserGestor = this._userService.isRoleLoggedUser('GESTOR');
    this.isUserEspecialist = this._userService.isRoleLoggedUser('ROLE_DISCIPLINA');
    this.isUserLicensing = this._userService.isRoleLoggedUser('ROLE_LICENCIAMENTO');

    const suspension = this.isSuspension ? 'Suspensão' : 'Cancelamento'
    this.textButtonsConfirm = 'Confirmar ' + suspension;
    this.textButtonsCancel = 'Cancelar ' + suspension;
    this.suspensionResponse = this.utilService.getAuthorization('insert', 'RESPONSESUSPENSION');
    this.suspensionView = this.utilService.getAuthorization('visible', 'RESPONSESUSPENSION');
    this.deadLineDate = this.addDays(this.suspensionStatus.confirmationSendDate,120);
    this.getDeadlineColorCssClass(this.deadLineDate);
  }

  public openConfirmationModal(): void {
    if(this.confirmMessage === '' || this.confirmMessage === null || this.confirmMessage === undefined){
      this.message.showErrorMessage("Existem campos obrigatórios pendentes de preenchimento. Favor verificar!")
      return
    } else if(this.confirmMessage.length > 2000){
      this.message.showErrorMessage("Comentários finais deve ter no máximo 2000 caracteres.")
      return
    } else {
      this.modalService.confirm({
        ...(this.buildCustomMessage(false)),
        nzOnOk: () => {
          this.sendCancelConfirmationUpdae(false);
        }
      })
    }
  }

  public openCancelationModal(): void {
    if(this.confirmMessage === '' || this.confirmMessage === null || this.confirmMessage === undefined){
      this.message.showErrorMessage("Existem campos obrigatórios pendentes de preenchimento. Favor verificar!")
      return
    } else if(this.confirmMessage.length > 2000){
      this.message.showErrorMessage("Comentários finais deve ter no máximo 2000 caracteres.")
      return
    } else {
      this.modalService.confirm({
        ...(this.buildCustomMessage(true)),
        nzOnOk: () => {
          this.sendCancelConfirmationUpdae(true);
        }
      });
    }
  }

  sendCancelConfirmationUpdae(canceled: boolean) {
    const request = {
      id: this.confirmationSuspensionId,
      comments: this.confirmMessage,
      isAbandon: canceled
    }
    this.executePath(request);
  }

  private executePath(request: { comments: string | undefined; isAbandon: boolean; id: number }) {

    const route = buildRoute(RoutesEnum.PROJECT_BY_ID, {
      projectId: `${this.projectId}`
    });

    this.suspensionCancellationService.patchIsAbandonedSuspension(request).subscribe({
      next: data => {
        if (data) {
          const modal = this.modalService.create<ConfirmationModalComponent>({
            nzTitle: '',
            nzClosable: false,
            nzWidth: 555,
            nzCentered: true,
            nzMaskClosable: false,
            nzKeyboard: false,
            nzContent: ConfirmationModalComponent,
            nzViewContainerRef: this.viewContainerRef,
            nzFooter: null,
          });

          const instance = modal.getContentComponent();
          instance.title = `O ${request.isAbandon ? 'cancelamento' : 'aceite'} da requisição foi realizado com sucesso.`
          instance.buttonAction = () => {
            this._router.navigate([route]);
          }
        }
      }
    })
  }

  buildCustomMessage(cancelled: boolean): any {
    return {
      nzTitle: `Deseja mesmo ${cancelled ? 'cancelar?' : 'confirmar?'}`,
      nzContent:'Essa ação não poderá ser desfeita.',
      nzOkText: this.isSuspension ? (cancelled ? 'Cancelar suspensão' : 'Confirmar suspensão') : (cancelled ? 'Abandonar cancelamento' : 'Confirmar cancelamento'),
      nzOkType: 'primary',
      nzOkDanger: cancelled,
      nzCancelText: 'Voltar',
      nzCentered: true,
      nzWidth: 580,
      nzClosable: false
    };
  }
  setConfigMessage(event) {
    this.confirmMessage = event;
  }

  addDays(dateStr: string, days: number): Date {
    const date = new Date(dateStr);
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  calculateDaysFromToday(dateStr: Date): number {
    const today = new Date();
    const timeDiff = today.getTime() - dateStr.getTime();
    const dayDiff = timeDiff / (1000 * 3600 * 24);
    return Math.floor(dayDiff);
  }

  getDeadlineColorCssClass(deadLineDate: Date):void  {
    if (deadLineDate) {
      const days = this.calculateDaysFromToday(deadLineDate);
      if (days < -105) {
        this.deadLineColor = 'black';
        this.badgeColor = 'warning';
        this.badgeIcon = 'icon_warning_neutral';
      } else if (days > -105 && days <= -15) {
        this.badgeColor = 'warning';
        this.deadLineColor = 'yellow-bold';
        this.badgeIcon = 'icon_warning_neutral';
      } else if (days > -15) {
        this.badgeColor = 'info';
        this.deadLineColor = 'red';
        this.badgeIcon = 'icon_warning_red';
      }
    }

  }

  shouldShowSuspensionBlock(): boolean {
    return( this.isUserGestor || this.isUserLicensing || this.isUserEspecialist ) &&
            this.suspensionStatus.confirmationDate === null &&
            this.suspensionStatus.finalComments === null &&
           this.suspensionStatus.confirmationSendDate;
  }
}
