import { selectIsChatOpen } from './../../../../shared/ngrx/chat-ai/chat-ai.selector';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ChatComponent } from '../chat/chat.component';
import { StartChatAIComponent } from '../../components/chat-view/start-chat-ai/start-chat-ai.component';
import { GenericTableConfiguration, GenericTableHeader, InputEditableConfig } from 'src/app/shared/components/tables/generic-table/generic-table.component';
import { ChatAiService } from '../../service/chat-ai.service';
import { AlertMessageService } from 'src/app/shared/services/alert-message.service';
import { FilterChatAi } from '../../interface/commons';
import moment from 'moment';
import { IGetAllChats } from '../../interface/historic-chats-view';
import { NgrxAiUtilsService } from '../../service/ngrx-ai/ngrx-ai-utils.service';
import { setPages } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import { Page } from 'src/app/shared/ngrx/breadcrumb/pages.model';
import { Store } from '@ngrx/store';
import { BreadcrumbState } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';

@Component({
  selector: 'app-historic-chats',
  templateUrl: './historic-chats.component.html',
  styleUrl: './historic-chats.component.scss'
})
export class HistoricChatsComponent implements OnInit {

  @ViewChild('templateChatFilter', { static: true }) templateChatFilter!: TemplateRef<any>;
  @ViewChild('templateDateFilter', { static: true }) templateDateFilter!: TemplateRef<any>;
  @ViewChild('templateDocumentFilter', { static: true }) templateDocumentFilter!: TemplateRef<any>;
  @ViewChild('templateChatIcon', { static: true }) templateChatIcon!: TemplateRef<any>;

  editableConfiguration: InputEditableConfig;
  configurationTable: GenericTableConfiguration;
  dataHeaderTable: GenericTableHeader[] = [];
  isLoading: boolean = true;
  dataListTable: any = [];
  dataListTableCopy: any = [];
  targetRowEdit: any;

  paramsFilter: FilterChatAi;

  showEditIcon: boolean = false;
  cleanValuesFilter: boolean = false;
  isChatOpen: boolean = false;
  isSendEmail: boolean = true;

  constructor(
    private chatAiService: ChatAiService,
    private store: Store<BreadcrumbState>,
    private ngrxAiUtilsService: NgrxAiUtilsService,
    private alertMessageService: AlertMessageService,
  ) { }

  ngOnInit(): void {
    this.initConfigurationTable();
    this.initFilterObject();
    this.getAllChats();
    this.selectIsReloadChatOnStore();
    this.selectIsChatOpen();
    this.dispatchProjectOnStore();
  }

  private dispatchProjectOnStore(): void {
    const pagesArray: Page[] = [
      {
        name: "Pergute à AI",
        url: ''
      }
    ];
    this.store.dispatch(setPages({pages: pagesArray}));
  }

  openNewChatAi() {
    if (this.isChatOpen) {
      const buttonBackChat = document.getElementById('btn-back-chatList');
      if (buttonBackChat) buttonBackChat.click();
      setTimeout(() => {
        const newChatButton = document.getElementById('open_chat_by_HistoricChatComponent');
        if (newChatButton) newChatButton.click();
      }, 1000);
    } else {
      const button = document.getElementById('open_chatAi_btn');
      if (button) {
        button.click();
        setTimeout(() => {
          const newChatButton = document.getElementById('open_chat_by_HistoricChatComponent');
          if (newChatButton) newChatButton.click();
        }, 1000);
      }
    }
  }

  onChangeValueCol(event: IGetAllChats): void {
    const openChat = () => {
      let retryCount = 0; // Contador para limitar tentativas
      const maxRetries = 180; // Número máximo de tentativas (3 min)

      const checkButtonExistence = setInterval(() => {
        const newChatButton = document.getElementById(`chat${event.chatId}`);
        retryCount++;

        if (newChatButton) {
          newChatButton.click();
          clearInterval(checkButtonExistence);
        } else if (retryCount >= maxRetries) {
          clearInterval(checkButtonExistence);
        }
      }, 1000);
    };

    if (this.isChatOpen) {
      const buttonBackChat = document.getElementById('btn-back-chatList');
      if (buttonBackChat) buttonBackChat.click();
      setTimeout(openChat, 1000);
    } else {
      const button = document.getElementById('open_chatAi_btn');
      if (button) {
        button.click();
        setTimeout(openChat, 1000);
      }
    }
  }

  initConfigurationTable(): void {
    this.configurationTable = {
      isCheckbox: false,
      isResizeColumn: true,
      isClickRowEvent: true,
      isDisableRow: { field: 'isActive' },
    }

    this.dataHeaderTable = [
      { field: 'chatTitle', label: 'Conversa', isLink: true, templateName: this.templateChatFilter,
        templateIcon: this.templateChatIcon, disableTooltip: true, isEditable: true
      },
      { field: 'chatDate', label: 'Data', templateName: this.templateDateFilter, disableTooltip: true  },
      { field: 'documentsName', subField: '', label: 'Documentos', templateName: this.templateDocumentFilter, disableTooltip: true  }
    ]
  }

  onDataValueChanged(event): void {}

  onChangeHoverValue(event: IGetAllChats): void {
    this.targetRowEdit = event;
    this.showEditIcon = event.isActive;
  }

  onChangeEditRow($event) {
    this.editableConfiguration = {
      id: null,
      targetField: 'chatId',
      editable: false
    }

    if (this.targetRowEdit) {
      this.chatAiService.callChatAiEndpoint(null, `/chats/?chat_id=${this.targetRowEdit.chatId}&chat_name=${this.targetRowEdit.chatTitle}`)
      .subscribe({
        error: (error) => {
          this.alertMessageService.send('Infelizmente não foi possível atualizar o nome do chat.', 'error');
          this.getAllChats();
        },
        complete: () => {
          this.alertMessageService.send('O nome do chat foi atualizar com sucesso.', 'success');
        }
      })
    }
  }

  onEditRow() {
    this.editableConfiguration = {
      id: this.targetRowEdit.chatId,
      targetField: 'chatId',
      editable: true
    }

    setTimeout(() => {
      const buttonBackChat = document.getElementById(`input${this.editableConfiguration.id}`);
      if (buttonBackChat) buttonBackChat.focus();
    }, 1000);
  }

  onSendEmail() {
    if (!this.targetRowEdit) return;
    if (!this.showEditIcon && !this.isSendEmail) return;
    this.isSendEmail = false;
    let params: {chatId: string; title: string} = {chatId: this.targetRowEdit.chatId, title: this.targetRowEdit.chatTitle};
    this.chatAiService.callChatAiEmail(params).subscribe({
      error: (error) => {
        this.alertMessageService.send('Infelizmente não foi possível enviar o e-mail. Favor, tentar novamente.', 'error');
        setTimeout(() => { this.isSendEmail = true; }, 1000);
      },
      complete: () => {
        this.alertMessageService.send('Email enviado com sucesso, por favor verifique sua caixa de mensagens.', 'success');
        setTimeout(() => { this.isSendEmail = true; }, 1000);
      }
    });
  }

  getAllChats(): void {
    this.chatAiService.getAllChats()
      .subscribe({
        next: (response: IGetAllChats[]) => {
          this.dataListTable = response.sort((a, b) => moment(b.chatDate).diff(moment(a.chatDate)));
          this.dataListTableCopy = this.dataListTable;
        },
        error: (err) => {
          this.isLoading = false; // loading da tabela
          this.alertMessageService.send(`${err.error.errors.details}`, 'error');
        },
        complete: () => {
          this.isLoading = false; // loading da tabela
        }
      });
  }

  filterByTitle(chatTitle: string): void {
    this.paramsFilter.chat = chatTitle;
    this.filterList();
  }

  filterByDocuments(document: string): void {
    this.paramsFilter.documents = document;
    this.filterList();
  }

  filterByDate(event:any): void {
    this.paramsFilter.date = moment(event.date).format('YYYY-MM-DDT00:00:00');
    this.paramsFilter.period = event.period;
    this.filterList();
  }

  filterList(): void {
    this.dataListTable = this.dataListTableCopy.filter(chat => {
      const matchesTitle = this.paramsFilter.chat ? chat.chatTitle.toLowerCase().includes(this.paramsFilter.chat.toLowerCase()) : true;
      const matchesDocuments = this.paramsFilter.documents ? (chat.documentsName && chat.documentsName.some(doc => doc.toLowerCase().includes(this.paramsFilter.documents?.toLowerCase() || ''))) : true;
      const matchesDate = this.paramsFilter.date ? moment(chat.chatDate).isSameOrAfter(this.paramsFilter.date) : true;
      const matchesPeriod = this.paramsFilter.period ? moment(chat.chatDate).isSameOrBefore(this.paramsFilter.period) : true;
      return matchesTitle && matchesDocuments && matchesDate && matchesPeriod;
    });
  }

  initFilterObject(): void {
    this.paramsFilter = {
      chat: null,
      documents: null,
      date: null,
      period: null,
    }
    this.dataListTable = this.dataListTableCopy;
  }

  onCleanFilter(event) {
    if (event) {
      this.initFilterObject();
      this.cleanValuesFilter = true;
    }
  }

  selectIsReloadChatOnStore() {
    this.ngrxAiUtilsService.selectIsReloadChatOnStore()
    .subscribe({
      next: (response) => {
        if (response) this.getAllChats();
      }
    });
  }

  selectIsChatOpen() {
    this.ngrxAiUtilsService.selectIsChatOpentOnStore()
    .subscribe({
      next: (response) => { this.isChatOpen = response; }
    })
  }

}
