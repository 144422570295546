import {HttpClient, HttpHeaders} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {KeycloakService} from 'keycloak-angular'
import Keycloak, {type KeycloakProfile, type KeycloakTokenParsed} from 'keycloak-js'
import {lastValueFrom, Observable} from 'rxjs'
import {DASHBOARD_API, ENTITIES, LOGOUT_API} from 'src/app/modules/projects/constants/apiEndpointsConstants'
import {UserService} from "../../../shared/services/user.service";
import {UrlSegment} from "@angular/router";
import {UtilService} from "../../../shared/util.service";

@Injectable()
export class AuthService {
  constructor(private readonly keycloakService: KeycloakService, private readonly http: HttpClient, private readonly userService: UserService, private readonly utilService: UtilService) {
  }

  public getLoggedUser(): KeycloakTokenParsed | undefined {
    try {
      const user = sessionStorage.getItem("user");
      const userDetails: KeycloakTokenParsed | undefined = this.keycloakService.getKeycloakInstance()
        .idTokenParsed
      if (!user) {
        sessionStorage.setItem("user", JSON.stringify(userDetails));
      }
      return userDetails
    } catch (e) {
      return undefined
    }
  }

  public async isLoggedIn(): Promise<boolean> {
    return await this.keycloakService.isLoggedIn()
  }

  public async loadUserProfile(): Promise<KeycloakProfile> {
    return await this.keycloakService.loadUserProfile()
  }

  public login(): void {
    const option: Keycloak.KeycloakLoginOptions = {
      redirectUri: window.location.origin + '/',
    }
    void this.keycloakService.login(option)
  }

  public logout(): Observable<any> {
    const idToken = this.keycloakService.getKeycloakInstance().idToken;
    let headers = new HttpHeaders();
    headers = headers.append('idToken', idToken ? idToken : "");
    return this.http.get<any>(LOGOUT_API, {headers})
  }

  public redirectToProfile(): void {
    void this.keycloakService.getKeycloakInstance().accountManagement()
  }

  public getRoles(): string[] {
    const roles = sessionStorage.getItem('roles');
    if (!roles) {
      sessionStorage.setItem("roles", JSON.stringify(this.keycloakService.getUserRoles()));
    }
    return this.keycloakService.getUserRoles()
  }

  public async getApiUser() {
    const userRecuperado = sessionStorage.getItem('loggedUser');
    if (!userRecuperado) {
      await lastValueFrom(this.userService.getLoogedUser()).then(
        (data) => {
          if (data) {
            sessionStorage.setItem("loggedUser", JSON.stringify(data));
          }
        }
      )
    }
  }

  async getConfigRole() {
    const configRoles = sessionStorage.getItem('configRoles');
    if (!configRoles) {
      await lastValueFrom(this.userService.getConfigRoles()).then(
        (data) => {
          if (data) {
            sessionStorage.setItem("configRoles", JSON.stringify(data));
          }
        }
      )
    }
  }

  GetServicePrincipalAccessToken(reportId, groupId) {
    const body = {
      reportId: reportId,
      groupId: groupId
    }
    return this.http.post<any>(DASHBOARD_API + "/getAccess", body)
  }

  async isActiveItem(url: UrlSegment[]) {
    if (url.length > 2) {
      let isActive = true;
      const entity = this.utilService.getEntity(url[url.length - 2].path);
      const id = url[url.length - 1].path;
      if (entity === '') {
        return true;
      }
      await lastValueFrom(this.http.get<any>(`${ENTITIES}/is-active?entityName=${entity}&id=${id}`)).then(
        value => {
          isActive = value.isActive;
        }
      )
      return isActive;
    } else return true;

  }
}
