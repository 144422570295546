<section class="communications__section">
  <header class="header">
    <div class="header__info">
      <div class="header__info__left">
        <div class="header__info__left__text">
          <h1 class="header__info__left__text__title">
            <!-- Comunicações com órgãos competentes -->
            {{ "projects.communication.list-title" | translate }}
          </h1>
        </div>
      </div>
      <div class="header__actions">
        <button *ngIf="cadCommunicationsInsert" nz-popover nzPopoverPlacement="bottom" nzPopoverTrigger="click"
          [nzPopoverContent]="listCommunication" [nzPopoverOverlayStyle]="popoverOverlayStyle"
          [(nzPopoverVisible)]="popOverNotification" class="btn__primary btn__primary__green">
          <span class="btn__primary__span">
            {{ "projects.communication.button-new-communication" | translate }}
          </span>
          <div class="btn__primary__line"></div>
          <span nz-icon>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M5.03436 6.63422C5.34678 6.3218 5.85331 6.3218 6.16573 6.63422L8.00005 8.46853L9.83436 6.63422C10.1468 6.3218 10.6533 6.3218 10.9657 6.63422C11.2782 6.94664 11.2782 7.45317 10.9657 7.76559L8.00005 10.7313L5.03436 7.76559C4.72194 7.45317 4.72194 6.94664 5.03436 6.63422Z"
                stroke="white" stroke-width="0.7" fill="white" />
            </svg>
          </span>
        </button>
        <ng-template #listCommunication>
          <div class="btnNewCommunication" (click)="newCommunication('ENV')">
            {{ 'projects.communication.button-communication-sent' | translate}}
          </div>
          <div class="btnNewCommunication" (click)="newCommunication('REC')">
            {{ 'projects.communication.button-communication-received' | translate}}
          </div>
        </ng-template>
      </div>
    </div>
  </header>

  <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="communications__card">
    <div class="communications__card__header">
      <h2 class="communications__card__header__title">
        {{ 'projects.communication.general-information' | translate }}
      </h2>
      <button type="button" class="communications__card__header__btn">
        <img src="assets/icons/icon_configuracoes.svg" alt="Configurações">
      </button>
    </div>
    <nz-spin [nzSpinning]="isLoading" id="spinner">
      <nz-table #filterTable #sortTable #borderedTable nzTableLayout="fixed" [nzFrontPagination]="false"
        class="communications__table" [nzData]="communicationsListFiltered"
        [nzScroll]="{ y: returnHeightOnResolution() }">
        <thead>
          <tr>
            <th id="cummunicationsType" nzWidth="4%">
              <img src="assets/icons/icon_large_mail_sended_and_received.svg" alt="Comunicações enviadas/recebidas">
            </th>

            <!-- TITULO DA COMUNICAÇÃO -->
            <th nzCustomFilter id="communication1" class="communications__table__th" [nzShowFilter]="false"
              [nzWidth]="listOfColumns[1].width">
              <div class="d-flex__inline">
                <span class="communications__table__th__span">
                  {{ listOfColumns[1].name | translate}}
                </span>

                <app-modal-order-by class="ml-8" (changeValue)="onOrderBy($event, 'title')">
                </app-modal-order-by>

                <app-generic-modal-search [cleanValues]="cleanValuesFilter"
                  (changeSearchValue)="filterBy($event, 'title')"
                  (changeCleanValue)="onCleanFilter($event)">
                </app-generic-modal-search>
              </div>
            </th>

            <!-- NOME DO PROJETO -->
            <th nzCustomFilter id="communication2" class="communications__table__th" [nzShowFilter]="false"
              [nzWidth]="listOfColumns[2].width">
              <div class="d-flex__inline">
                <span class="communications__table__th__span">
                  {{ listOfColumns[2].name | translate}}
                </span>

                <app-modal-order-by class="ml-8" (changeValue)="onOrderBy($event, 'projectName')">
                </app-modal-order-by>

                <app-generic-modal-search [cleanValues]="cleanValuesFilter"
                  (changeSearchValue)="filterBy($event, 'projectName')"
                  (changeCleanValue)="onCleanFilter($event)">
                </app-generic-modal-search>
              </div>
            </th>

            <!-- ORGÃO -->
            <th nzCustomFilter class="communications__table__th" [nzShowFilter]="false"
              [nzWidth]="listOfColumns[4].width" id="communication4">
              <div class="d-flex__inline">
                <span class="communications__table__th__span">
                  {{ listOfColumns[4].name | translate}}
                </span>

                <app-modal-order-by (changeValue)="onOrderBy($event, 'organ')">
                </app-modal-order-by>

                <app-modal-filter-checkbox class="ml-8" 
                  [cleanValues]="cleanValuesFilter"
                  [configuration]="{isScroll: true}" 
                  [filterTableOptions]="filterByOrganView"
                  (changeSearchValue)="filterBy($event, 'organ')"
                  (changeCleanValue)="onCleanFilter($event)">
                </app-modal-filter-checkbox>
              </div>
            </th>

            <!-- NOME DO PROCESSO -->
            <th nzCustomFilter id="communication3" class="communications__table__th" [nzShowFilter]="false"
              [nzWidth]="listOfColumns[3].width">
              <div class="d-flex__inline">
                <span class="communications__table__th__span">
                  {{ listOfColumns[3].name | translate}}
                </span>

                <app-modal-order-by (changeValue)="onOrderBy($event, 'processName')"></app-modal-order-by>
                
                <app-generic-modal-search [cleanValues]="cleanValuesFilter"
                  (changeSearchValue)="filterBy($event, 'processName')"
                  (changeCleanValue)="onCleanFilter($event)">
                </app-generic-modal-search>
              </div>
            </th>
            <!-- NUMERO DO PROCESSO -->
            <th nzCustomFilter id="communication5" class="communications__table__th" [nzShowFilter]="false"
              [nzWidth]="listOfColumns[5].width">
              <div class="d-flex__inline">
                <span class="communications__table__th__span">
                  {{ listOfColumns[5].name | translate}}
                </span>

                <app-modal-order-by class="ml-8" (changeValue)="onOrderBy($event, 'processNumber')">
                </app-modal-order-by>

                <app-generic-modal-search [cleanValues]="cleanValuesFilter"
                  (changeSearchValue)="filterBy($event, 'processNumber')"
                  (changeCleanValue)="onCleanFilter($event)">
                </app-generic-modal-search>
              </div>
            </th>
            
            <!-- DATA DO RECEBIMENTO DO ENVIO -->
            <th nzCustomFilter id="communicationDate" class="communications__table__th" [nzShowFilter]="false" [nzWidth]="listOfColumns[6].width">
              <div class="d-flex__inline">
                <span class="communications__table__th__span">
                  {{ listOfColumns[6].name | translate}}
                </span>

                <app-modal-order-by class="ml-8" (changeValue)="onOrderBy($event, 'date')"></app-modal-order-by>

                <app-modal-prediction-conclusion
                  [cleanValues]="cleanValuesFilter"
                  (changeApplyFilter)="filterBy($event, 'date')"
                  (changeCleanValues)="onCleanFilter($event)">
                </app-modal-prediction-conclusion>
              </div>
            </th>

            <!-- DATA LIMITE PARA RESPOSTA  -->
            <th nzCustomFilter id="communicationLimitDate" class="communications__table__th" [nzShowFilter]="false" [nzWidth]="listOfColumns[7].width">
              <div class="d-flex__inline">
                <span class="communications__table__th__span">
                  {{ listOfColumns[7].name | translate}}
                </span>

                <app-modal-order-by class="ml-8" (changeValue)="onOrderBy($event, 'answerLimitDate')"></app-modal-order-by>

                <app-modal-prediction-conclusion
                  [cleanValues]="cleanValuesFilter"
                  (changeApplyFilter)="filterBy($event, 'answerLimitDate')"
                  (changeCleanValues)="onCleanFilter($event)">
                </app-modal-prediction-conclusion>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of filterTable.data" (click)="goToCommunication(item.id, item.processName)">
            <td>
              <img *ngIf="item.communicationType === 'REC'" src="assets/icons/icon_large_mail_received.svg"
                alt="Comunicação recebida">
              <img *ngIf="item.communicationType === 'ENV'" src="assets/icons/icon_large_mail_sended.svg"
                alt="Comunicação enviada">
            </td>
            <td class="communications__table__body__title" nz-tooltip [nzTooltipTitle]="item.title">
              {{ item.title }}
            </td>
            <td>{{ item.projectName}}</td>
            <td>{{ item.organ}}</td>
            <td>{{ item.processName}}</td>
            <td>{{ item.processNumber}}</td>
            <td>{{ item.date | date: "dd/MM/YYYY" }}</td>
            <td>{{ item.answerLimitDate | date: "dd/MM/YYYY" }}</td>
          </tr>
        </tbody>
      </nz-table>
    </nz-spin>
  </div>
