<div class="app-modal-search-options">
  
    <i class="pi pi-search ft__size12" [class.has__value]="isHasFilter" (click)="modalSearchOptions.toggle($event)"></i>

    <p-overlayPanel #modalSearchOptions>
        <div class="ant-table-filter-dropdown app-modal-search-options__overlay" style="height: 310px;">
            <div class="search__box">
                <nz-input-group [nzSuffix]="suffixIconSearch">
                    <input type="text" nz-input autofocus 
                        placeholder="Pesquisar" 
                        class="inputGroup input__search"
                        [(ngModel)]="searchValue"
                        (ngModelChange)="onSearchFilter()" />
                </nz-input-group>

                <ng-template #suffixIconSearch>
                    <img class="close__icon" src="/assets/icons/icon_search_green.svg" alt="Close icon" />
                </ng-template>
            </div>
            
            @if (showOptionsList.length === 0) {
                <div class="not__found">
                    <span> {{ msgNotFound }} </span>
                </div>
            }

            <div class="list__options">
                <ul>
                    <li *ngFor="let item of showOptionsList" (click)="onSelectOption(item)">
                        
                        @if (item.isSelected) {
                            <span class="selected-item">{{ item.label }} (Já Selecionado)</span>
                        } @else {
                            <span>{{ item.label }}</span>
                        }

                    </li>
                </ul>

            </div>

            <div class="buttons__box">
                <div class="hr"></div>
                <div class="buttons">
                    <button class="clean__button" nz-button nzSize="small" (click)="resetFilter()"> Limpar busca </button>
                </div>
            </div>
        </div>
    </p-overlayPanel>
</div>
