import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ScopeChangeRequest, ScopeChangeResponse} from "../../interface/scope-change-request";
import {ProjectsV2Service} from "../../../../shared/services/projects-v2.service";
import { UserService } from 'src/app/shared/services/user.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { RequestReviewModalComponent } from '../../components/request-review-modal/request-review-modal.component';
import { AnalysisInformations } from 'src/app/shared/dto/analysis-info.dto';
import { Observable, of, switchMap } from 'rxjs';
import { UserModel } from 'src/app/shared/models/user.model';
import { BreadcrumbState } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { ProjectState } from 'src/app/shared/ngrx/breadcrumb/model';
import { Location } from "@angular/common";

import { ProjectByIdResponseDto } from 'src/app/shared/dto/project-by-id-response.dto';
import {UtilService} from "../../../../shared/util.service";
import {ScopeChangeService} from "../../service/scope-change/scope-change.service";
import { buildRoute, RoutesEnum } from 'src/app/shared/enum/routes.enum';
import { setPages } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import { Page } from 'src/app/shared/ngrx/breadcrumb/pages.model';

@Component({
  selector: 'app-scope-change-analysis',
  templateUrl: './scope-change-analysis.component.html',
  styleUrls: ['./scope-change-analysis.component.scss']
})
export class ScopeChangeAnalysisComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<any> = new Subject();
  analysisInfo: AnalysisInformations;
  scopeChangeId: number;
  scopeChangeName: string;
  isReview: boolean  = false;
  scopeChange: ScopeChangeResponse | null = null;
  opinionStatus: 'HIDE' | 'PENDING' | 'CONSOLIDATE' | 'DRAFT' | 'EDIT' | 'DONE' = 'HIDE';

  project: ProjectByIdResponseDto;
  blockScopeChange: boolean = false;
  scopeChangeHistory: any
  scopeChangeHistoryList: number
  resolverData:any;
  statusReview: string | undefined = '';
  confirmScopeChange: boolean = false;
  anarequisition: boolean = false;
  scopechangerevision: boolean = false;
  cadScopeCHange: boolean = false;
  consolidationScopeChange: boolean = false;

  isClosedOrCancelled: boolean = false;
  isAllDisciplinesConcluded: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectsV2Service,
    private utilService: UtilService,
    private location: Location,
    private userService: UserService,
    private store: Store<BreadcrumbState>,
    private readonly modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private scopeChangeService: ScopeChangeService
  ) { }

  ngOnInit(): void {
    this.cadScopeCHange = this.utilService.getAuthorization('edit', 'CADSCOPECHANGE')
    this.confirmScopeChange = this.utilService.getAuthorization('insert', 'CONFIRMSCOPECHANGE')
    this.anarequisition = this.utilService.getAuthorization('insert', 'ANAREQUISITION');
    this.scopechangerevision = this.utilService.getAuthorization('insert', 'SCOPECHANGEREVISION');
    this.consolidationScopeChange = this.utilService.getAuthorization('insert', 'CONSOLIDATIONSCOPECHANGE');
    this.scopeChangeId = this.route.snapshot.params['id'];
    this.route?.queryParams?.subscribe(parametros => {
      this.scopeChangeName = parametros['scopeChangeName']
    })
    this.getScopeChangeById(this.scopeChangeId);
    this.getProject();
    this.statusReview = this.scopeChange?.subProjectReviewList[0]?.status;
    this.addMeResponsibleAfterFinalAnalysis();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  updateScopeChangeUser(newUser) {
    if (this.scopeChange) {
      this.scopeChange.userAnalisis = newUser;
      this.getAnalysisInfo();
    }
  }

  getAnalysisInfo() {
    this.analysisInfo = {
      number: this.scopeChange?.number || "",
      responsible: this.scopeChange?.userAnalisis || null,
      registrationDate: this.scopeChange?.registrationDate || "",
      deadline: this.scopeChange?.analysisDeadLine || "",
      status: this.scopeChange?.status || "INI"
    }
  }

  getScopeChangeById(scopeChangeId: number, isUseDataResolver: boolean = true) {
    if (isUseDataResolver) {
      this.resolverData = this.route?.snapshot?.data['scopeChangeAnalysis'];
      this.scopeChange = this.resolverData['scopeChangeData'];
    }
    const scopeChangeArray = this.scopeChange?.subProjectReviewList;
    this.scopeChangeHistory = scopeChangeArray ? scopeChangeArray.filter(item => item.status === "CON") : null;
    this.scopeChangeHistoryList = this.scopeChangeHistory?.length;
    const disciplines = this.scopeChange?.disciplineAssessments.filter(item => item.status !== 'CON');
    this.isReview = this.scopeChange?.status === 'REV' || (this.scopeChange?.status === 'CAN' && this.scopeChange.subProjectReviewList.length > 0);
    this.blockScopeChange = this.scopeChange?.status === 'CAN' || this.scopeChange?.status === 'CON';

    this.isClosedOrCancelled = (this.scopeChange?.status === 'CAN' || this.scopeChange?.status === 'CON');

    this.getAnalysisInfo();
    if(this.scopeChange?.confirmDeadLine && disciplines && disciplines.length > 0){
      this.opinionStatus = "EDIT"
    }
    else if(this.scopeChange?.confirmDeadLine){
      this.opinionStatus = "DONE"
    }
    else if (this.scopeChange?.impactDescription || this.scopeChange?.impactEstimated || this.scopeChange?.scopeChangeImpacts?.length > 0){
      this.opinionStatus = "DRAFT"
    } 
    else {
      if (this.scopeChange && this.scopeChange.disciplineAssessments && this.scopeChange.disciplineAssessments.length > 0) {
        this.isAllDisciplinesConcluded = this.scopeChange.disciplineAssessments.every(disc => disc.status === "CON");
        this.opinionStatus = this.isAllDisciplinesConcluded ? "PENDING" : "HIDE"
      } else {
        this.isAllDisciplinesConcluded = false;
      }
    }

  }

  getUserByRoleAndComplex = (): Observable<UserModel[]> | null => {
    if (this.scopeChange && this.scopeChange.complex && this.scopeChange?.complex.id) {
      return this.userService.getUsersByComplexAndRole(this.scopeChange.complex.id, 'ROLE_LICENCIAMENTO');
    }

    return null;
  }

  getProject() {
    this.project = this.resolverData['projectData'];
    this.dispatchProjectOnStore();
  }

  redirectToScopeChangePublishFinal() {
    const params = { id: this.project?.id };

    if (this.scopeChange) {
      const routeProject = buildRoute(RoutesEnum.SCOPE_CHANGE, {
        projectId: `${this.project?.id}`
      });

      const routeChangePublish= buildRoute(RoutesEnum.SCOPE_CHANGE_PUBLISH, {
        id: `${this.scopeChange.id}`
      });

      this.router.navigate([`${routeProject}/${routeChangePublish}`]);
    }

  }

  updateDisciplines() {
    this.reloadDataPage();
    this.addMeResponsibleIfResponsibleIsNull();
  }

  goBack(){
    this.location.back();
  }


  // INICIO BREADCRUMB
  private dispatchProjectOnStore(): void {
    const routeProject = buildRoute(RoutesEnum.PROJECT_BY_ID, {
      projectId: this.project.id
    });

    const pagesArray: Page[] = [
      {
        name: "Projetos",
        url: `/${RoutesEnum.PROJECTS}`
      },
      {
        name: this.project.name,
        url: `${routeProject}`
      },
      {
        name: 'Mudança de escopo',
        url: ''
      },
    ];

    this.store.dispatch(setPages({pages: pagesArray}));
  }

  openReviewRequestModal(){

    const modal = this.modal.create<RequestReviewModalComponent>({
      nzTitle: 'Solicitar revisão da requisição',
      nzClosable: true,
      nzWidth: 600,
      nzCentered: true,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzContent: RequestReviewModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });
    const instance = modal.getContentComponent();
    instance.scopeChangeId = this.scopeChangeId;

    modal.afterClose.subscribe((success: boolean) => {
      if (success) {
        this.addMeResponsibleIfResponsibleIsNull();
        this.reloadDataPage();
      }
    })
  }

  addLoggedUserAsResponsible() {
    this.userService.loadUserSession();
    let user: UserModel = this.userService.getUserFromSession();
    this.updateScopeChangeUser(user);

    if(user.id === undefined) return;

    const request = this.buildPayload(user.id);
    this.scopeChangeService
    .updateUserAnalisis(this.scopeChangeId, request)
    .subscribe({
      next: () => {
        this.reloadDataPage();
      },
      error: () => {
      },
    });
  }

  private buildPayload(userId: number) {
    var request: ScopeChangeRequest = {
      areaId: this.scopeChange ? this.scopeChange?.area.id : 0,
      classificationType: this.scopeChange
        ? this.scopeChange?.classificationType
        : '',
      comments: this.scopeChange ? this.scopeChange?.comments : '',
      complexId: this.scopeChange ? this.scopeChange?.complex.id : 0,
      isDraft: false,
      operationalUnitId: this.scopeChange?.operationalUnit
        ? this.scopeChange?.operationalUnit.id
        : 0,
      projectCode: this.scopeChange ? this.scopeChange?.code : '',
      projectId: this.scopeChange ? this.scopeChange?.projectId : 0,
      regionId: this.scopeChange?.region ? this.scopeChange?.region.id : 0,
      scopeCategory: this.scopeChange?.scopeChangeReasons
        ? this.scopeChange?.scopeChangeReasons.map((ca) => ca.id)
        : [0],
      scopeDescription: this.scopeChange
        ? this.scopeChange?.impactDescription
        : '',
      userLegalRepId: this.scopeChange?.userLegalRepresentative
        ? this.scopeChange?.userLegalRepresentative.id
        : 0,
      userAnalisisId: userId,
    };
    return request;
  }

  existSubProjectReviewList(): boolean {
    return this.scopeChange?.subProjectReviewList.filter(review => review.status != 'CON').length == 0;
  }

  onReloadData($event) {
    if ($event) {
      this.reloadDataPage();
    }
  }

  reloadDataPage(): void {
    this.projectService.getScopeChange(this.scopeChangeId).pipe(
      switchMap(scopeChangeData => {
        return this.projectService.projectCache(scopeChangeData.projectId).pipe(
          switchMap(projectData => {
            return of({
              projectData: projectData,
              scopeChangeData: scopeChangeData,
            })
          })
        )
      })
    ).subscribe((result) => {
      this.scopeChange = result.scopeChangeData;
      this.project = result.projectData;
      this.getScopeChangeById(this.scopeChangeId, false);
    });

  }

  addMeResponsibleAfterFinalAnalysis() {
    if(this.scopeChange && this.scopeChange?.confirmDeadLine && this.scopeChange?.userAnalisis === null){
      this.addLoggedUserAsResponsible();
    }
  }

  addMeResponsibleIfResponsibleIsNull() {
    if(this.scopeChange && this.scopeChange?.userAnalisis === null){
      this.addLoggedUserAsResponsible();
    }
  }
}
