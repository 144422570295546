import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { firstValueFrom, takeUntil } from 'rxjs';
import { AttachmentsModel } from 'src/app/shared/models/attachments.model';
import { setAttachments, setIsReloadChat, setIsChatOpen } from 'src/app/shared/ngrx/chat-ai/chat-ai.actions';
import { selectAttachmentsState, selectIsChatOpen, selectIsReloadChat, selectPages } from 'src/app/shared/ngrx/chat-ai/chat-ai.selector';
import { AttachmentsState } from 'src/app/shared/ngrx/chat-ai/chat-ai.state.model';
import { IAttachment } from 'src/app/shared/ngrx/chat-ai/pages.model';

@Injectable({
  providedIn: 'root'
})
export class NgrxAiUtilsService {

  constructor(
    private store: Store<AttachmentsState>
  ) { }

  dispatchAttachmentListOnStore(attachments: IAttachment[]) {
    this.store.dispatch(setAttachments({ attachments }));
  }

  dispatchIsReloadChatOnStore(isReloadChat: boolean) {
    this.store.dispatch(setIsReloadChat({ isReloadChat }));
  }

  dispatchIsChatOpenOnStore(isChatOpen: boolean) {
    this.store.dispatch(setIsChatOpen({ isChatOpen }));
  }

  selectAttachmentListOnStore() {
    return this.store.pipe(select(selectPages));
  }

  selectIsReloadChatOnStore() {
    return this.store.pipe(select(selectIsReloadChat));
  }

  selectIsChatOpentOnStore() {
    return this.store.pipe(select(selectIsChatOpen));
  }

  async pushAttachmentListOnStore(attachments: IAttachment[]) {
    var updatedArray;
    const currentAttachs = await firstValueFrom(this.store.pipe(select(selectPages)));
    updatedArray = currentAttachs.concat(attachments.filter(newAttachment =>
      !currentAttachs.some(existingAttachment => existingAttachment.id === newAttachment.id)
    ));
    this.store.dispatch(setAttachments({ attachments: updatedArray }));
  }

  buildAttachmentAdditionalByStore(data: AttachmentsModel[]): any[] {
      const attachments: IAttachment[] = [];
      data.forEach(elem => {
        const attachmentItem: IAttachment = {
          attachment: {
            id: elem.id!,
            entityId: elem.entityId!,
            entityName: elem.entityName!,
            name: elem.name,
            description: elem.description,
            version: elem.version!,
            comments: '',
            user: null,
            date: elem.date!
          },
          mandatoryDoc: {
            id: elem.id!,
            name: elem.name,
            types: '',
            classificationType: '',
            projType: '',
          },
          id: elem.id!,
          name: elem.name  
        }
        attachments.push(attachmentItem);
      });
     
      return attachments
    }
}
