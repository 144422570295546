import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NotificationsComponent } from 'src/app/modules/notifications/notifications/notifications.component';
import { UserProfileComponent } from 'src/app/modules/user-profile/user-profile.component';
import { RoutesEnum } from 'src/app/shared/enum/routes.enum';
import { UtilService } from 'src/app/shared/util.service';
import {AuthService} from "../../auth/service/auth.service";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  private flagUserProfile = false;
  private drawerRef;
  private notificationsDrawer;

  public notificationActiveFlag = false;
  nonProd: boolean = false;

  isViewConfigurationUser: boolean = false;
  configurationActive: boolean = true;


  constructor(
    private drawerService: NzDrawerService,
    private readonly util: UtilService,
    private readonly authService: AuthService,
    private router: Router
  ){}

  async ngOnInit() {
    await this.authService.getConfigRole().then(() => {
      this.isViewConfigurationUser = this.util.getAuthorization('visible', 'USERCONFIGURATION');
    })
  }

  notificationsToggle(){
    if (!this.notificationActiveFlag) {
      const bodyStyle = {
        padding: 20,
      };
      this.notificationsDrawer = this.drawerService.create<
        NotificationsComponent,
        { value: string },
        string
      >({
        nzContent: NotificationsComponent,
        nzOffsetX: 0,
        nzBodyStyle: bodyStyle,
        nzPlacement: 'right',
        nzTitle: 'Notificações',
        nzWidth: 680,
      });

      this.notificationsDrawer.afterOpen.subscribe(() => {
        this.notificationActiveFlag = true;
      });

      this.notificationsDrawer.afterClose.subscribe((data) => {
        this.notificationActiveFlag = false;
      });
    } else {
      this.notificationsDrawer.close();
      this.notificationsDrawer = null;
    }
  }

  userProfile() {
    if (!this.flagUserProfile) {
      const bodyStyle = {
        padding: 20,
      };
      this.drawerRef = this.drawerService.create<
        UserProfileComponent,
        { value: string },
        string
      >({
        nzContent: UserProfileComponent,
        nzOffsetX: 0,
        nzBodyStyle: bodyStyle,
        nzPlacement: 'right',
        nzTitle: 'Perfil',
        nzWidth: 300,
      });

      this.drawerRef.afterOpen.subscribe(() => {
        this.flagUserProfile = true;
      });

      this.drawerRef.afterClose.subscribe((data) => {
        this.flagUserProfile = false;
      });
    } else {
      this.drawerRef.close();
      this.drawerRef = null;
    }
  }

  configuration() {
    this.router.navigate([`${RoutesEnum.CONFIGURATION}`]);
  }
}
