<div class="app-chat">

    <div class="container__button">
        @if (showLoading) {
            <svg width="56" height="60" viewBox="0 0 250 250" class="circular-progress" [class.rotate__animation]="showLoading" [style]="returnProgressBar()">
                <circle class="fg"></circle>
            </svg>
        }

        <div class="button__icon" id="open_chatAi_btn" (click)="showChat()">
          <img src="assets/icons/icon_ai_particles_purple24.svg" [class.opacity100]="!showChatAI" *ngIf="!showChatAI">
          <img src="assets/icons/icon_double_down_purple24.svg" [class.opacity100]="showChatAI" *ngIf="showChatAI">
        </div>
        <span id="idTooltipChatAi" (click)="op.toggle($event)"></span>
        <p-overlayPanel #op [dismissable]="false" [showCloseIcon]="true" (onHide)="onHidenTooltip()" styleClass="tooltipChatAi">
          <div class="content"> Prontinho! <br> Pode perguntar. </div>
        </p-overlayPanel>
    </div>


    @if (showChatAI) {
      <div class="container__chat" [class.maximize]="maximize" [class.animate-in]="showChatAI">
          <div class="header" [class.pt-32]="!showActionBar">
            @if(showActionBar)
            {
              <app-action-bar (action)="callAction($event)"></app-action-bar>
            }

            <app-card-title [configCardTitle]="configCardTitle"></app-card-title>
          </div>

          <div class="content">
              @if(!showConversation)
              {
                <app-start-chat-ai #startChatAi (rowClick)="onRowClick($event)" (newChat)="startConversation()"></app-start-chat-ai>
              }

              @if(showConversation) {
                <app-conversation-chat-ia
                  #conversationChatAi
                  [chatId]="chatId"
                  [chatTargetFiles]="currentChat?.last_target_files || []"
                  [messages]="messages"
                  [alreadySendingFiles]="isSendingFile"
                  [showModalSendEmail]="showModalSendEmail"
                  [feedbackEmail]="feedbackEmail"
                  (sendFilesStatus)="fileSendingStatus($event)"
                  (sendEmail)="sendEmail($event)"
                  (currentChat)="onSendEmailByNewChat($event)"
                  (updateChatId)="onUpdateChatIdBySendMessage($event)">
                </app-conversation-chat-ia>
              }
          </div>
      </div>
    }
</div>
