<div class="app-generic-edit-table">
    <p-table
        [value]="dataList" 
        [resizableColumns]="true" 
        styleClass="p-datatable-gridlines"
        [tableStyle]="{'min-width': '50rem'}">
        <ng-template pTemplate="header">
            <tr>
                @if (dataConfiguration.isResizeColumn) {
                    @for (header of dataHeaders; track $index) {
                        <th pResizableColumn [id]="header.field+'-th'">
                            <div class="content__display__th">
                                {{ header.label }}
                                <ng-container *ngTemplateOutlet="header.templateName"></ng-container>
                                <span class="custom__border__right"></span>
                            </div>
                        </th>
                    }
                }

                @if (!dataConfiguration.isResizeColumn) {
                    @for (header of dataHeaders; track $index) {
                        <th [id]="header.field+'-resize-th'">
                           <div class="content__display__th">
                                {{ header.label }}
                                <ng-content [select]="header.templateName"></ng-content>
                                <span class="custom__border__right"></span>
                           </div>
                        </th>
                    }
                }

                @if (dataConfiguration.isDeleteIcon || dataConfiguration.isEditIcon) {
                    <th id="lastcolumn-th"></th>
                }
            </tr>
        </ng-template>
        
        <ng-template pTemplate="body" let-rowData>
            <tr>
                @for (dataHeader of dataHeaders; track $index) {
                    <td [pEditableColumn]="rowData[dataHeader.field]" [pEditableColumnField]="rowData[dataHeader.field]">
                        
                        <!-- INPUT TEXT -->
                        @if (dataHeader.typeInput === 'text') {
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input
                                        pInputText 
                                        type="text" 
                                        class="input__size"
                                        [(ngModel)]="rowData[dataHeader.field]" 
                                        (focusout)="onCreateOrEditRowInputText($event, rowData, dataHeader.nextFocusInput)"
                                        (keydown)="onKeyDownInputText($event, rowData, dataHeader.nextFocusInput)"
                                        [class.text__placeholder]="rowData._isValidInputText !== false"
                                        [class.invalid__filed]="rowData._isValidInputText === false"
                                        (ngModelChange)="onModelChangeInputText($event, rowData, dataHeader.isRequired, dataHeader.inputTextConfig?.maxLegth)"/>
                                </ng-template>
        
                                <ng-template pTemplate="output">
                                    <div 
                                        [id]="dataHeader.field+'-'+rowData.id"
                                        class="input__size" 
                                        [class.ft__color__gray]="rowData.id === 0"
                                        [class.text__placeholder]="rowData._isValidInputText !== false"
                                        [class.invalid__filed]="rowData._isValidInputText === false">
                                            @if (!rowData[dataHeader.field] && rowData.id === 0) {
                                                <span> {{ dataHeader.insertRowConfig?.placeholder }} </span>
                                            } 
                                            @if (rowData[dataHeader.field]) {
                                                @if (dataHeader.inputTextConfig?.isTooltip) {
                                                    <div class="ellipsis__text" nz-tooltip [nzTooltipTitle]="rowData[dataHeader.field]">
                                                        {{ rowData[dataHeader.field] }}
                                                    </div>
                                                } @else {
                                                    <div class="ellipsis__text">
                                                        {{ rowData[dataHeader.field] }}
                                                    </div>
                                                }
                                            }
                                    </div>
                                </ng-template>
                            </p-cellEditor>
                        }


                        <!-- DROPDOWN -->
                        @if (dataHeader.typeInput === 'select') {
                            <p-cellEditor [id]="dataHeader.field+'-'+rowData.id">
                                <ng-template pTemplate="input">
                                    <nz-select
                                        id="idSelectGenericEditableTable"
                                        [(ngModel)]="rowData[dataHeader.field]"
                                        (nzScrollToBottom)="onloadMoreSelect(dataHeader.field)"
                                        [nzPlaceHolder]="dataHeader.dropdownConfig?.dropdownPlaceHolder || ''"
                                        nzAllowClear
                                        nzShowSearch
                                        [nzStatus]="rowData._isValidSelect === false ? 'error' : ''"
                                        [nzDropdownRender]="renderTemplate"
                                        [nzNotFoundContent]="renderTemplateNotFound"
                                        [nzSuffixIcon]="''"
                                        [nzDropdownStyle]="{ 'border-radius': '8px' }"
                                        (nzOnSearch)="resizeSelectOptionsOverlay($event)"
                                        (ngModelChange)="onModelChangeInputSelect($event, rowData, dataHeader.isRequired, dataHeader.nextFocusInput)">
                                        <nz-option *ngFor="let o of dataHeader.dropdownConfig?.dropdownOptions" [nzValue]="o" [nzLabel]="o.label"></nz-option>
                                    </nz-select>

                                    <ng-template #renderTemplate>
                                        <nz-spin *ngIf="isLoading"></nz-spin>
                                    </ng-template>

                                    <ng-template #renderTemplateNotFound>
                                        <div class="content__not__found">
                                            
                                            <div class="text" *ngIf="dataHeader.dropdownConfig?.dropdownNoContentMessage">
                                                {{ dataHeader.dropdownConfig?.dropdownNoContentMessage }}
                                            </div>

                                            <div class="icon" *ngIf="!dataHeader.dropdownConfig?.dropdownNoContentMessage">
                                                <img src="assets/icons/archive_gray-icon24.svg" alt="Icon">
                                                <span>Não a dados</span>
                                            </div>
                                        </div>
                                    </ng-template>

                                </ng-template>
        
                                <ng-template pTemplate="output">
                                    <div class="input__size"
                                        [class.ft__color__gray]="rowData.id === 0"
                                        [class.text__placeholder]="rowData._isValidSelect !== false"
                                        [class.invalid__filed]="rowData._isValidSelect === false">
                                        
                                        @if (!rowData[dataHeader.field]) {
                                            
                                            @if(dataHeader.dropdownConfig?.dropdownIconNewRow) {
                                                <div class="iconSelectNewRow">
                                                   <img [src]="'assets/icons/' + (dataHeader.dropdownConfig?.dropdownIconNewRow) + '.svg'" alt="Icon">
                                                </div>
                                            } @else {
                                                <span> {{ dataHeader.dropdownConfig?.dropdownPlaceHolder }} </span>
                                            }
                                        } @else {
                                            {{ rowData[dataHeader.field].label }}
                                        }
                                        
                                    </div>
                                </ng-template>
                            </p-cellEditor>
                        }
                    </td>
                }

                @if (dataConfiguration.isDeleteIcon || dataConfiguration.isEditIcon) {
                    <td (click)="deleteRowData(rowData)">
                        @if (rowData.id !== 0) {
                            <div class="td__edit__delete__icons">
                                <img src="/assets/icons/icon_trash_green12.svg" *ngIf="dataConfiguration.isDeleteIcon" alt="excluir"/>
                                <img class="ml-8" src="/assets/icons/edit_green-icon24.svg" *ngIf="dataConfiguration.isEditIcon" alt="editar"/>
                            </div>
                        }
                    </td>
                }

            </tr>
        </ng-template>
    </p-table>
</div>
