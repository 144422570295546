import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';

export interface IModalSearchOptions { 
  value: any, 
  label: string, 
  isSelected: boolean 
}

@Component({
  selector: 'app-modal-search-options',
  templateUrl: './modal-search-options.component.html',
  styleUrl: './modal-search-options.component.scss',
})
export class ModalSearchOptionsComponent implements AfterViewInit {

  private _options: IModalSearchOptions[] = [];
  private _cleanValues: boolean = false;

  isHasFilter = false;
  
  @Input() msgNotFound: string = '';

  @Input() 
  set options(value: IModalSearchOptions[]) {
    this._options = value.filter((option, index, self) =>
      index === self.findIndex((t) => t.value === option.value)
    );
    this.showOptionsList = this._options;
    this.isHasFilter = this.options.some(opt => opt.isSelected);
  }

  @Input()
  set cleanValues(value: boolean) {
    if (value !== this._cleanValues) {
      this._cleanValues = value;
      this.resetFilter();
    }
  };
  
  get options(): IModalSearchOptions[] {
    return this._options;
  }

  @Output() changeSearchValue: EventEmitter<IModalSearchOptions[] | null> = new EventEmitter<IModalSearchOptions[] | null>();
  @Output() changeCleanValue: EventEmitter<boolean> = new EventEmitter<boolean>();

  searchValue = '';
  showOptionsList: IModalSearchOptions[] = [];

  ngAfterViewInit(): void {
    this.showOptionsList = this._options;
  }

  onSearchFilter(): void {
    this.showOptionsList = this.options
      .filter(option => option.label.toUpperCase().includes(this.searchValue.toUpperCase()));
  }

  resetFilter(): void {
    this.searchValue = '';
    this._cleanValues = false;
    this.isHasFilter = false;
    this._options = this._options.map(opt => ({ ...opt, isSelected: false }));
    this.showOptionsList = this._options;
    this.changeSearchValue.emit([]);
    this.changeCleanValue.emit(true);
  }

  onSelectOption(option: IModalSearchOptions): void {
    
    this.options = this.options.map(opt => 
      opt.value === option.value ? { ...opt, isSelected: !opt.isSelected } : opt
    );

    this.isHasFilter = this.options.some(opt => opt.isSelected);

    this.changeSearchValue.emit(this.options.filter(opt => opt.isSelected));
    this.onSearchFilter();
  }

}
