import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// PrimeNG
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CalendarModule } from 'primeng/calendar';

// Zorro
import { NzInputModule } from 'ng-zorro-antd/input';

// Components
import { GenericModalSearchComponent } from './filter-components/modal-search/generic-modal-search.component';
import { GenericModalPeriodDateComponent } from './filter-components/modal-period-date/modal-period-date.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { ButtonsLicensingModule } from '../buttons/buttons-licensing.module';
import { ModalFilterCheckboxComponent } from './filter-components/modal-checkbox/app-modal-filter-checkbox.component';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { GenericTableComponent } from './generic-table/generic-table.component';
import { IconsLicensingModule } from '../icons/icons-licensing.module';
import { ModalOrderByComponent } from './filter-components/modal-order-by/modal-order-by.component';
import { SharedModule } from '../../shared.module';
import { ModalPredictionConclusionComponent } from './filter-components/modal-prediction-conclusion/modal-prediction-conclusion.component';
import { GenericEditTableComponent } from './generic-edit-table/generic-edit-table.component';
import { DropdownModule } from 'primeng/dropdown';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { ModalSearchOptionsComponent } from './filter-components/modal-search-options/modal-search-options.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

const COMPONENTS = [
  GenericTableComponent,
  GenericModalSearchComponent,
  GenericModalPeriodDateComponent,
  ModalFilterCheckboxComponent,
  ModalOrderByComponent,
  ModalPredictionConclusionComponent,
  GenericEditTableComponent,
  ModalSearchOptionsComponent
]

const MODULES = [
  TableModule,
  TooltipModule,
  CalendarModule,
  OverlayPanelModule,
  NzInputModule,
  NzDividerModule,
  NzCheckboxModule,
  ButtonsLicensingModule,
  IconsLicensingModule,
  DropdownModule,
  SharedModule,
  NzSelectModule,
  NzSpinModule,
  NzToolTipModule
]

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...MODULES
  ]
})
export class TableLicensingModule { }
