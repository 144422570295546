import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {models} from "powerbi-client";
import {AuthService} from "../../../../core/auth/service/auth.service";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-dashboard-ripla',
  templateUrl: './dashboard-ripla.component.html',
  styleUrls: ['./dashboard-ripla.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardRiplaComponent implements OnInit{

  workspace: string = environment.workspace
  dashRipla: string = environment.dashRipla

  embedConfig: any;
  constructor(private readonly authService: AuthService) {}


  ngOnInit(): void {

    this.authService.GetServicePrincipalAccessToken(this.dashRipla,this.workspace).subscribe({
      next: data => {
        if(data){
          this.embedConfig = {
            type: "report",
            id: this.dashRipla,
            embedUrl: "https://app.powerbi.com/reportEmbed?reportId=a4306164-dcc4-4eba-9c8d-dbd837e7dcbb",
            accessToken: data.accessToken,
            tokenType: models.TokenType.Embed,
            settings: {
              layoutType: models.LayoutType.Master,
              zoomLevel: 0.75,
              customLayout: {
                pageSize: { type: models.PageSizeType.Cortana},
                displayOption: models.DisplayOption.FitToWidth,
                reportAlignment: models.ReportAlignment.Center
              },
              panes: {
                filters: {
                  expanded: false,
                  visible: false
                }
              },
              background: models.BackgroundType.Transparent,
            }
          }
        }
      }
    })

  }
}
