import { Component, Input, inject } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrl: './generic-modal.component.scss'
})
export class GenericModalComponent {
  readonly #modal = inject(NzModalRef);

  @Input() iconName: string | null;
  @Input() modalTitle!: string;
  @Input() modalText: string;
  @Input() informativeText: string | null;
  @Input() cancelationButtonText: string = "Não";
  @Input() confirmButtonText: string = "Sim";

  constructor() {
    // This is intentionally empty to render the compoenent
  }

  cancelAction() {
    this.#modal.close(false)
  }

  executeAction() {
    this.#modal.close(true);
  }
}