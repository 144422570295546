import { Component, Input, OnInit } from '@angular/core';
import { TextShortnerHelper } from './text-shortner.helper';

@Component({
  selector: 'app-short-text-hint',
  templateUrl: './short-text-hint.component.html',
  styleUrls: ['../../../shared/styles/global-style-v2.scss']
})
export class ShortTextHintComponent implements OnInit {
  @Input() originalText: string;
  @Input() maxChars: number;
  textOutput: string;
  showHint = false;

  constructor(private readonly shortnerHelper: TextShortnerHelper) { }

  ngOnInit(): void {
    if (!this.originalText) return;
    this.textOutput = this.shortnerHelper.textShortner(this.originalText, this.maxChars);
    this.showHint = this.shortnerHelper.isTooBig(this.originalText, this.maxChars);
  }
}
