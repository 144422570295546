<div class="app-my-studies sl__simpleLayout">
  <header>
    <div class="alignFlex">
      <h1 class="title">{{ "suppliers.my-studies.title" | translate }}</h1>
      <p class="subtitle">{{ "suppliers.my-studies.subtitle" | translate }}</p>
    </div>
  </header>

  <div class="outer__wrapper">

    <div nz-row [nzGutter]="[16, 16]">
      <div nz-col [nzSpan]="24">

        <!-- BLOCO DE ESTUDOS -->
        <div class="round-border mb-16">
          <app-generic-table
            style="min-width: 40rem !important" 
            [dataConfiguration]="configurationTable"
            [dataHeaders]="dataHeaderTable"
            [isLoading]="isLoading"
            [dataList]="dataListTable.content ? dataListTable.content : []" 
            (changeValueCol)="onSelectStudy($event)"
          >
        </app-generic-table>

        </div>

    </div>
  </div> <!-- outer__wrapper -->
</div>
