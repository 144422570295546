import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { COMMUNICATIONS_API } from '../projects/constants/apiEndpointsConstants';
import { CommunicationListRequestParams } from './interfaces/CommunicationListRequestParams';

export interface Communication {
  id: number,
  communicationType: string,
  title: string,
  projectName: string,
  projectId: number,
  processName: string,
  organ: string,
  processNumber: number,
  date: string,
  answerLimitDate: string,
}

export interface List {
  pageable: {
    sort: {
      empty: boolean,
      sorted: boolean,
      unsorted: boolean
    },
    offset: number,
    pageSize: number,
    pageNumber: number,
    paged: boolean,
    unpaged: boolean
  },
  last: boolean,
  totalPages: number,
  totalElements: number,
  size: number,
  number: number,
  sort: {
    empty: boolean,
    sorted: boolean,
    unsorted: boolean
  },
  numberOfElements: number,
  first: boolean,
  empty: boolean
}

export interface CommunicationsList extends List {
  content: Communication[];
}

@Injectable({
  providedIn: 'root'
})
export class CommunicationsService {

  constructor(
    private _http: HttpClient
  ) { }

  getCommunicationsList(page: number, size: number, paramsFilter?: CommunicationListRequestParams): Observable<CommunicationsList> {
    let httpParams = new HttpParams()
    .set('page', page.toString())
    .set('size', size.toString())


    if (paramsFilter) {
      Object.keys(paramsFilter).forEach(key => {
        if (paramsFilter[key] !== undefined && paramsFilter[key] !== null && paramsFilter[key].length > 0) {
          httpParams = httpParams.append(key, paramsFilter[key]);
        }
      });
    }

    if (paramsFilter?.sort) {
      httpParams = httpParams.set('sort', paramsFilter.sort);
    }
    
    return this._http.get<CommunicationsList>(`${COMMUNICATIONS_API}`, { params: httpParams });
  }
}
