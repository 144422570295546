import { Component, OnInit, ViewContainerRef, TemplateRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

import { Page } from 'src/app/shared/ngrx/breadcrumb/pages.model';
import { setPages } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import { BreadcrumbState } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AlertMessageService } from 'src/app/shared/services/alert-message.service';
import { TranslateService } from '@ngx-translate/core';
import { SumarizeActivityResponse } from 'src/app/modules/projects/projects-licensing/licensing.interface';
import { buildRoute, RoutesEnum } from 'src/app/shared/enum/routes.enum';
import { Details } from 'src/app/shared/components/resume/generic-resume/generic-resume.component';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from 'src/app/shared/util.service';
import { GenericTableComponent, GenericTableConfiguration, GenericTableHeader } from 'src/app/shared/components/tables/generic-table/generic-table.component';
import { Pageable, Sort } from 'src/app/shared/dto/communications-list-response.dto';
import { ConsultancyDeliverablesListRequestParams } from 'src/app/modules/configuration/interface/configuration-user-view';
import { StatusOptions } from 'src/app/modules/projects-v2/interface/activities-delivered';
import { ConsultingConfigurationServices } from '../../service/consultancy-configuration.services';

@Component({
  selector: 'app-my-studies',
  templateUrl: './my-studies.component.html',
  styleUrls: ['./my-studies.component.scss']
})
export class MyStudiesComponent implements OnInit {

  configurationTable: GenericTableConfiguration
  dataHeaderTable: GenericTableHeader[] = [];
  dataListTable: any = {
    content: [],
    pageable: {} as Pageable,
    size: 0,
    sort: {} as Sort,
    totalElements: 0,
    numberOfElements: 0,
    totalPages: 0
  };

  currentPage: number = 0;
  totalPages: number = 1;
  isLoading: boolean = false;

  totalElements: number = 0;
  totalItemsPage: number = 0;
  disableEditAttr: boolean = true;
  userIds: number[] = [];

  //TABLE
  @ViewChild('templateStudyFilter', { static: true }) templateStudyFilter!: TemplateRef<any>;
  @ViewChild('templateProjectFilter', { static: true }) templateProjectFilter!: TemplateRef<any>;
  @ViewChild('templateProcessFilter', { static: true }) templateProcessFilter!: TemplateRef<any>;
  @ViewChild('templateDeliverablesFilter', { static: true }) templateDeliverablesFilter!: TemplateRef<any>;
  @ViewChild(GenericTableComponent) genericTableComponent!: GenericTableComponent;

  hasDisabledButtonContinue: boolean = true;
  loading: boolean = true;
  idConsultingConfiguration: number = 0;
  aboutActivity: SumarizeActivityResponse;
  resumeDetails: Details[] = [];

  studyId: string | null
  projectId: string | null
  processId: string | null
  activityId: string | null
  studyName: string | null

  cleanValuesFilter: boolean = false;
  paramsFilter: ConsultancyDeliverablesListRequestParams;
  statusOptions = StatusOptions;
  statusOptionsFilter: { label: string; value: string; checked: boolean }[]= [];
  versionsOptionsFilter: Array<{label: string, value: string, checked: boolean}> = [];

    constructor(
    private location: Location,
    private store: Store<BreadcrumbState>,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private alertMessageService: AlertMessageService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private consultingConfigurationServices: ConsultingConfigurationServices,
  ) { }

  ngOnInit(): void {
    this.projectId = this.activatedRoute.snapshot.queryParamMap.get('projectId')
    this.processId = this.activatedRoute.snapshot.queryParamMap.get('processId')
    this.activityId = this.activatedRoute.snapshot.queryParamMap.get('activityId')
    this.activatedRoute.params.subscribe(params => {
      this.studyId = params['studyId']
      this.studyName = params['studyName']
    })
    this.initFilterObject();
    this.initConfigurationTable();
    this.getMyStudies();
    this.dispatchProjectOnStore();
  }

  initConfigurationTable(): void {
    this.configurationTable = {
      isCheckbox: false,
      isResizeColumn: true,
      isClickRowEvent: false
    }

    this.dataHeaderTable = [
      { field: 'study', isLink: true, label: 'Estudo', templateName: this.templateStudyFilter, disableTooltip: true  },
      { field: 'project', label: 'Projeto', templateName: this.templateProjectFilter, disableTooltip: true },
      { field: 'process', label: 'Processo', templateName: this.templateProcessFilter },
      { field: 'deliverables', label: 'Entregáveis aprovados', templateName: this.templateDeliverablesFilter, disableTooltip: true }
    ]
  }

  onCleanFilter(event) {
    if (event) {
      this.initFilterObject();
      this.cleanValuesFilter = true;
      this.isLoading = false
    }
  }

  initFilterObject(): void {
    this.paramsFilter = {
      study: null,
      project: null,
      process:null,
      name: null,
      deliverables:null,
      responsible: null,
      expectedDate: null,
      expectedDateStart: null,
      expectedDateEnd: null,
      version: null,
      status: null,
    }
  }

  onChangeValueTable(event: any, type: string): void {
    if (type === 'templateStudyFilter') this.paramsFilter.study = event;
    if (type === 'templateProjectFilter') this.paramsFilter.project = event;
    if (type === 'templateProcessFilter') this.paramsFilter.process = event;
    if (type === 'templateDeliverablesFilter') this.paramsFilter.deliverables = event;
  }

  getMyStudies() {
    this.consultingConfigurationServices.getMyStudies().subscribe({
      next: result => {
        this.formatDataToStudiesTable(result)
      },
      error: error => {
        this.alertMessageService.send(`${error.message}`, 'error');
      }
    })
  }

  formatDataToStudiesTable(data) {
    data.forEach(item => {
      const newStudy = {
        study: item.activity.title,
        project: item.project.name,
        process: item.projectLicense.title,
        deliverables: item.approvedDeliverables + " de " + item.deliverablesTotal,
      }
      this.dataListTable.content.push(newStudy)
    })
  }

  resetDataListContent() {
    this.dataListTable.content = [];
    this.currentPage = 0;
    this.totalPages = 1;
    this.isLoading = false
  }

  // BREADCRUMB
  private dispatchProjectOnStore(): void {

    const routeStudy = buildRoute(RoutesEnum.EDIT_PROCESS_V2, {
      projectId: Number(this.projectId),
      processId: Number(this.processId),
    });

    const pagesArray: Page[] = [
      {
        name: "Estudos ambientais",
        url: `projetos/${routeStudy}`
      },
      {
        name: "Meus estudos",
        url: `meus-estudos`
      },
    ];
    this.store.dispatch(setPages({ pages: pagesArray }));
  }


  goBack() {
    this.location.back();
  }

  onSelectStudy(updatedValues: any): void {
  }

}
