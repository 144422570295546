import { Video } from "src/app/shared/models/video.model";

export const VIDEOS: Video[] = [
  {
    key: "processes",
    title: "Quando e como criar um processo?",
    description: "Inicie a elaboração dos processos de licenciamento logo após a publicação da RIPLA para planejar e acompanhar todas as atividades necessárias para a sua realização.",
    thumbnail: "/assets/video/sample.png",
    url: "https://stkeycloak.blob.core.windows.net/videos/CadastroRipla.mp4?sp=r&st=2024-12-18T11:29:30Z&se=2025-12-30T19:29:30Z&spr=https&sv=2022-11-02&sr=b&sig=3%2Bg9lzlySgQmUm8dgVj2j9uTi8xZ4a27MhjPTz%2FgQ64%3D"
  },
  {
    key: "discipline-analysis",
    title: "Inserir um link para o Anexo IV",
    description: "Para inserir um link para o Anexo IV, que será compartilhado com os responsáveis pela análise da requisição, siga o passo a passo do video.",
    thumbnail: "/assets/video/sample.png",
    url: "https://stkeycloak.blob.core.windows.net/videos/CriacaoAnexo4.mp4?sp=r&st=2024-12-18T11:30:48Z&se=2025-12-30T19:30:48Z&spr=https&sv=2022-11-02&sr=b&sig=pmiuLzJVq8oqAgmHmEpEN16OJSLjnI1OJbN0OEbgBlM%3D"
  },
];
