import { Component, ViewContainerRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAttachment } from 'src/app/shared/ngrx/chat-ai/pages.model';
import { NgrxAiUtilsService } from '../../../service/ngrx-ai/ngrx-ai-utils.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FileSelectionComponent } from '../file-selection/file-selection.component';
import { ChatAiService } from '../../../service/chat-ai.service';
import { AlertMessageService } from 'src/app/shared/services/alert-message.service';
import { IMessage } from '../../../interface/commons';

@Component({
  selector: 'app-select-files-list',
  standalone: false,
  templateUrl: './select-files-list.component.html',
  styleUrl: './select-files-list.component.scss'
})
export class SelectFilesListComponent implements OnInit {

  @Input() messages: IMessage[] = [];
  @Input() chatId: string = "";

  @Output() changeSelectedValue: EventEmitter<IAttachment[]> = new EventEmitter<IAttachment[]>();
  @Output() textSentToAi: EventEmitter<IMessage> = new EventEmitter<IMessage>();
  @Output() changeCallAction: EventEmitter<string> = new EventEmitter<string>();

  selectedAttachments: IAttachment[] = [];
  attachments: IAttachment[] = [];
  isAttachments: boolean = false;
  hasTouchedSetAttachment: boolean = false;
  loggedUser;

  notFoundFileText: string = 'Ops! <br> Não localizei documentos nesta página que possam ser inseridos na nossa conversa. Encontre os arquivos desejados clicando no botão abaixo.';
  hasAttachmentText: string = 'Listei aqui embaixo os documentos que encontrei nesta página, mas se quiser falar sobre outro é só selecionar.';

  constructor(
    private ngrxAiUtilsService: NgrxAiUtilsService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private chatAiService: ChatAiService
  ) {}

  ngOnInit() {
    const userSession = sessionStorage.getItem('loggedUser');
    this.loggedUser = userSession ? JSON.parse(userSession) : null;
    this.getAttachmentsSelectedFromStore();
  }

  getAttachmentsSelectedFromStore(isInitialCall: boolean = true): void {
    this.ngrxAiUtilsService.selectAttachmentListOnStore().subscribe((attachments) => {
      if (attachments.length > 0) {
        this.attachments = attachments.filter(file => (file?.attachment?.name.endsWith('.pdf') || file?.attachment?.name.endsWith('.docx'))
          || file?.name?.endsWith('.pdf'));
        this.isAttachments = true;

        const uniqueAttachments = new Map();
        this.attachments.forEach(attachment => {
          const id = attachment.attachment?.id || attachment.id;
          if (!uniqueAttachments.has(id)) {
            uniqueAttachments.set(id, attachment);
          }
        });
        this.attachments = Array.from(uniqueAttachments.values());

        this.attachments.sort((a, b) => {
          const nameA = a.attachment?.name || a.name;
          const nameB = b.attachment?.name || b.name;
          return (nameA ?? '').localeCompare(nameB ?? '');
        });

        if (!isInitialCall) {
          this.hasAttachmentText = "Listei aqui embaixo os documentos que você selecionou. Podemos continuar?"
        }
      } else {
        this.attachments = attachments;
      }
    });
  }

  sendFilesSelected(): void {
    this.sendNewFileToChat();
  }

  private sendNewFileToChat(): void {
    const selectedAttachmentIds = this.selectedAttachments.map(selected => {
      if (selected.attachment) return selected.attachment.id;
      else if (selected.mandatoryDoc) return selected.mandatoryDoc.id;
      else return selected.id;
    });

    const requestBody = {
      "type": "upload",
      "urls": selectedAttachmentIds
    };

    this.chatAiService.callChatAiEndpoint(requestBody, "/files/operation/").subscribe({
      next: (response) => {
        sessionStorage.setItem("operation_id", response.operation_id);
        this.emitTextToAi();
      }
    })
  }

  private emitTextToAi() {
    let fileNames = "";
    this.selectedAttachments.forEach(selected => {
      if (selected.attachment)
        fileNames += `<li><b>${selected.attachment.name}</b></li>`
      else if (selected.mandatoryDoc)
        fileNames += `<li><b>${selected.mandatoryDoc.name}</b></li>`
      else fileNames += `<li><b>${selected.name}</b></li>`
    })

    const content = `
      <p class="sent-files_text" style="font-size: 14px; margin-bottom: 0px !important;">Arquivos enviados:</p>
      <ul class="sent-files_list" style="list-style: outside !important;">
        ${fileNames}
      </ul>
    `

    this.textSentToAi.emit({
      "chat_id": this.chatId,
      "content": this.hasAttachmentText,
      "message_id": Math.random().toString(),
      "role": "ai"
    })

    this.textSentToAi.emit({
      "chat_id": this.chatId,
      "content": content,
      "message_id": Math.random().toString(),
      "role": "human"
    })
  }

  openFileSelection(): void {
    const modal = this.modal.create<FileSelectionComponent>({
      nzWidth: 640,
      nzClosable: false,
      nzCentered: true,
      nzKeyboard: false,
      nzMaskClosable: false,
      nzContent: FileSelectionComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    modal.afterClose.subscribe(() => {
      this.getAttachmentsSelectedFromStore(false);
    })
  }

  showButtonAddDocument(): boolean {
    let messageUsers = this.messages.find((message) => message.role === 'human');
    return messageUsers ? false : true;
  }

  verifyIfExistAttachment() {
    this.hasTouchedSetAttachment = true;
    if(this.attachments.length > 0) {
      this.isAttachments = true;
    }
  }

  onChangeAttachments() {
    this.changeSelectedValue.emit(this.selectedAttachments);
  }

  onChangeCallAction() {
    this.getAttachmentsSelectedFromStore();
    this.changeCallAction.emit('attachments');
  }

  // Requisição Http para enviar arquivos para IA
  onConfirm() {}
}
