import { on } from '@ngrx/store';
import { Component, EventEmitter, OnInit, Output, ViewContainerRef } from '@angular/core';
import { IChatAILocalStorage, IChatsAllResponse } from '../../../interface/chat-ia-view';
import { IChat } from '../../../interface/commons';
import { FileSelectionComponent } from '../file-selection/file-selection.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ChatAiService } from '../../../service/chat-ai.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';

@Component({
  selector: 'app-start-chat-ai',
  templateUrl: './start-chat-ai.component.html',
  styleUrl: './start-chat-ai.component.scss'
})
export class StartChatAIComponent implements OnInit {

  @Output() rowClick: EventEmitter<IChat> = new EventEmitter<any>();
  @Output() newChat: EventEmitter<string> = new EventEmitter<string>();

  chatsAllResponse: IChatsAllResponse = { chats: [] };
  loggedUser;

  isLoading: boolean = false;

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private chatAiService: ChatAiService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    const userSession = sessionStorage.getItem('user');
    this.loggedUser = userSession ? JSON.parse(userSession) : null;

    this.getAllChatAI();
  }

  getAllChatAI() {
    if (this.loggedUser) {
      this.isLoading = true;
      const chatAiConfig = this.localStorageService.getItem('chatAiConfiguration') as IChatAILocalStorage;

      if(!chatAiConfig || (chatAiConfig && !chatAiConfig?.used)){
        this.isLoading = false;
        let informationStorage: IChatAILocalStorage = {
          chat_id: chatAiConfig?.chat_id,
          showModalSendEmail: chatAiConfig?.showModalSendEmail ? chatAiConfig?.showModalSendEmail : true,
          used: true
        }
        this.localStorageService.setItem('chatAiConfiguration', informationStorage);
        this.newChatClick();
      } else  {

        this.chatAiService.callChatAiEndpoint(null, `/chats/all/?username={%USER_EMAIL%}`).subscribe({
          next: (response) => {
            this.chatsAllResponse = response;
            this.isLoading = false;
          }, error: (error) => {
            this.isLoading = false;
          }
        })
      }
    }
  }

  onRowClick(chat: IChat) {
    this.rowClick.emit(chat);
  }

  newChatClick() {
    this.newChat.emit('newChat');
  }

  openFileSelection(): void {
    this.modal.create<FileSelectionComponent>({
      nzWidth: 640,
      nzClosable: false,
      nzCentered: true,
      nzKeyboard: false,
      nzMaskClosable: false,
      nzContent: FileSelectionComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });
  }

}
