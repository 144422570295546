<section class="app-analysis-required sl__simpleLayout">
  <div class="sl__simpleLayout__card">
    <header class="sl__simpleLayout__card__header">
      <h2 class="sl__simpleLayout__card__header__title">
        <!-- Sobre a requisição -->
        {{ "required-analyzes.required-analyzes" | translate }}
      </h2>
    </header>
    <div class="discipline-list">
      <nz-table nzTemplateMode nzSize="small">
        <thead>
          <tr>
            <th class="tableTh" id="disciplina">
              {{ "required-analyzes.discipline" | translate }}
            </th>
            <th class="tableTh" id="responsible">
              {{ "required-analyzes.responsible" | translate }}
            </th>
            <th class="tableTh" id="deadline">
              {{ "required-analyzes.deadline" | translate }}
            </th>
            <th class="tableTh" id="status">
              {{ "required-analyzes.status" | translate }}
            </th>
            <th class="tableTh" id="empty"></th>
          </tr>
        </thead>
        <tbody id="tableDiscipline">
          <tr
            class="editable-row"
            id="riplaInfo"
            *ngFor="
              let discipline of scopeChange.disciplineAssessments;
              let i = index">
            <td class="tableTdDiscipline">
              <div *ngIf="editingRow === i">
                <nz-select
                  nzShowSearch
                  nzAllowClear
                  nzBorderless
                  nzPlaceHolder="Informe a disciplina"
                  [(ngModel)]="selectedDisciplineId"
                  [compareWith]="compareFn"
                  (ngModelChange)="
                    getUsersByDisciplineIdAndComplexId(selectedDisciplineId)
                  "
                  class="formFieldBorder"
                >
                  <nz-option
                    *ngFor="let discipline of disciplinesList"
                    nzLabel="{{ discipline.description }}"
                    nzValue="{{ discipline.id }}"
                  >
                  </nz-option>
                </nz-select>
              </div>
              <span
                *ngIf="editingRow !== i"
                class="disciplineName"
                (click)="goToDiscipline(discipline.id)">
                <app-short-text-hint
                  [originalText]="discipline.discipline.description"
                  [maxChars]="50"
                ></app-short-text-hint>
              </span>
            </td>

            <td>
              <div *ngIf="editingRow === i">
                <nz-select
                  [(ngModel)]="selectedUserResponsibleIdDiscipline"
                  [compareWith]="compareFn"
                  nzBorderless
                  class="formFieldBorder"
                >
                  <nz-option
                    *ngFor="let user of usersSpecialist"
                    [nzValue]="user.id"
                    [nzLabel]="user.name !== undefined ? user.name : ''"
                  ></nz-option>
                </nz-select>
              </div>
              <span class="userItem selectItem" *ngIf="editingRow !== i">
                <app-short-text-hint
                  [originalText]="discipline.userAssessment.name"
                  [maxChars]="32"
                ></app-short-text-hint>
              </span>
            </td>

            <td class="tableTd" *ngIf="editingRow !== i">
              {{ discipline.analysisDeadline | date : "dd/MM/yyyy" }}
            </td>

            <td class="tableTd statusTd" *ngIf="editingRow !== i">
              <app-badge
                *ngIf="discipline.status"
                [badgeText]="discipline.status"
                [badgeColor]="isDeadLineDate(discipline) ? 'red' : null"
              ></app-badge>
            </td>

            <td class="tableTd" *ngIf="editingRow === i" colspan="2">
              <div class="formFieldBorder">
                <nz-date-picker
                  id="editDateDeadlineDiscipline"
                  nzFormat="dd/MM/yyyy"
                  [nzPlacement]="'topRight'"
                  [nzDisabledDate]="disabledDate"
                  [(ngModel)]="editDateDeadlineDiscipline"
                  class="disciplineDatePicker"
                  nzSuffixIcon=""
                  nzBorderless>
                </nz-date-picker>
              </div>
            </td>

            <td style="padding: 0 !important">
              <!-- TRES PONTOS MENU -->
              <div>
                <button
                  *ngIf="anaRequisition && !blockScopeChange && (createDisciplineStudy && !isEditing) && !this.isClosedOrCancelled"
                  class="buttonMenuFlow"
                  nz-button
                  nz-dropdown
                  [nzTrigger]="'click'"
                  [nzDropdownMenu]="menu">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none">
                    <ellipse
                      cx="10.5"
                      cy="10.5"
                      rx="1.5"
                      ry="1.5"
                      transform="rotate(-90 10.5 10.5)"
                      fill="#007E7A"/>
                    <ellipse
                      cx="10.5"
                      cy="4.5"
                      rx="1.5"
                      ry="1.5"
                      transform="rotate(-90 10.5 4.5)"
                      fill="#007E7A"/>
                    <ellipse
                      cx="10.5"
                      cy="16.5"
                      rx="1.5"
                      ry="1.5"
                      transform="rotate(-90 10.5 16.5)"
                      fill="#007E7A"/>
                  </svg>
                </button>
              </div>

              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                  <li
                    nz-menu-item
                    class="itemMenuFlow"
                    (click)="startEditDiscipline(i, discipline)"
                  >
                    <img
                      src="assets/icons/icon_edit.svg"
                      class="mr-10 icone"
                      alt=""
                    />
                    <span class="titleMenuItem">Editar</span>
                  </li>
                  <li
                    nz-menu-item
                    class="itemMenuFlow"
                    (click)="deleteDiscipline(discipline.id)"
                  >
                    <img
                      src="assets/icons/icon_delete.svg"
                      class="mr-10 icone"
                      alt=""
                    />
                    <span class="titleMenuItem">Excluir</span>
                  </li>
                </ul>
              </nz-dropdown-menu>
            </td>
          </tr>
          <tr *ngIf="editingRow !== null" class="add-discipline-action">
            <td colspan="5">
              <button
                (click)="stopEditDiscipline()"
                nz-button
                class="btn btn-green btnCancelDiscipline"
              >
                Cancelar
              </button>
              <button
                (click)="updateDiscipline()"
                nz-button
                nzType="primary"
                class="btnAddDiscipline"
              >
                Editar
              </button>
            </td>
          </tr>

          <!-- BLOCO PARA ADICIONAR NOVA DISCIPLINA -->
          <tr *ngIf="anaRequisition && !blockScopeChange && addNewDiscipline" id="umDosTres">
            <!-- LISTA DE DISCIPLINAS -->
            <td>
              <nz-select
                nzShowSearch
                nzAllowClear
                nzBorderless
                nzPlaceHolder="Informe a disciplina"
                [(ngModel)]="newIdDiscipline"
                [compareWith]="compareFn"
                (ngModelChange)="getUsersByDisciplineIdAndComplexId(newIdDiscipline)"
                class="formFieldBorder">
                <nz-option
                  *ngFor="let discipline of disciplinesList"
                  nzLabel="{{ discipline.description }}"
                  nzValue="{{ discipline.id }}">
                </nz-option>
              </nz-select>
            </td>
            <td>
              <!-- RESPONSAVEL PELA DISCIPLINA -->
              <nz-select
                [(ngModel)]="newUserResponsibleIdDiscipline"
                [compareWith]="compareFn"
                nzBorderless
                class="formFieldBorder"
                nzPlaceHolder="Informe o responsável">
                <nz-option
                  *ngFor="let user of usersSpecialist"
                  [nzValue]="user.id"
                  [nzLabel]="user.name !== undefined ? user.name : ''"
                ></nz-option>
              </nz-select>
            </td>
            <td colspan="2">
              <!-- PRAZO DISCIPLINA -->
              <div class="formFieldBorder">
                <nz-date-picker
                  id="newDateDeadlineDiscipline"
                  nzFormat="dd/MM/yyyy"
                  [nzPlacement]="'topRight'"
                  [nzDisabledDate]="disabledDate"
                  [(ngModel)]="newDateDeadlineDiscipline"
                  class="disciplineDatePicker"
                  nzSuffixIcon=""
                  nzBorderless
                  nzPlaceHolder="Informe o prazo">
                </nz-date-picker>
              </div>
              <!-- STATUS DISCIPLINA -->
              <select
                [hidden]="true"
                [(ngModel)]="newStatusDiscipline"
                id="stateSelectOption"
                class="gridSelectOption"
              >
                <option value="EAN" [selected]="true">Em análise</option>
              </select>
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>

          <tr *ngIf="anaRequisition && addNewDiscipline" class="add-discipline-action">
            <td colspan="5">
              <button
                (click)="cancelNewDisciplineClick()"
                nz-button
                class="btn btn-green btnCancelDiscipline"
              >
                Cancelar
              </button>
              <button
                (click)="addNewDisciplineDB()"
                nz-button
                nzType="primary"
                class="btnAddDiscipline"
              >
                Adicionar
              </button>
            </td>
          </tr>
          <!-- FIM BLOCO PARA ADICIONAR NOVA DISCIPLINA -->

          <tr>
            <td colspan="5">
              <div
                class="addNewDisciplineButton"
                *ngIf="anaRequisition && !blockScopeChange && createDisciplineStudy && disciplinesList.length > 0  && !this.isClosedOrCancelled">
                <span
                  class="project-doc-icon"
                  (click)="addNewDisciplineClick()"
                >
                  <img
                    src="/assets/icons/icon_plus_circle.svg"
                    alt="{{ 'projects.view.license.add-license' }}"
                  />
                </span>
                <span class="addNewDiscipline" (click)="addNewDisciplineClick()"
                  >Adicionar nova disciplina</span
                >
              </div>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</section>
